import React, { useEffect, useState, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import {
  Container,
  Row,
  Text,
  Div,
  Col,
  Card,
  Grid,
} from "../../styles/Common";
import { Button } from "../../components/Button";
import useFBApiConvertion from "../../core/hooks/useFBApiConvertion";
import { getUserIP } from "../../utilities/helpers";
import { theme } from "../../styles/theme";
import bComplexImg from "../../assets/images/bComplex.png";
import women from "../../assets/images/women-Complex.jpg";
import {
  CheckPasswordIcon,
  HealtIcon,
  LeafIcon,
  LightingIcon,
  PriceIcon,
  UserCheckIcon,
  VerifyIcon,
  ChevronRightIcon,
} from "../../assets/icons";
import { StarFilled } from "@ant-design/icons";
import { testimonials } from "./helpers";
import { ImgWrapper } from "../Deodorant/styles";
import bComplex1 from "../../assets/images/bcomplex1.png";
import bComplex2 from "../../assets/images/bcomplex2.png";
import bComplex3 from "../../assets/images/bcomplex3.png";
import bComplexVideo from "../../assets/videos/bComplex2.mp4";

export const BComplex = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: 768px)`,
  });

  const productsDataRef = useRef(null);

  const fbApiConvertion = useFBApiConvertion();

  const fbConvertionEvent = async (event) => {
    const ip = await getUserIP();
    const viewContentData = {
      data: [
        {
          event_name: event,
          event_time: Math.floor(new Date().getTime() / 1000),
          action_source: "website",
          user_data: {
            client_ip_address: ip,
            client_user_agent: navigator.userAgent,
            country: [
              "c330ec504d82c24fcd10be978fa74b8a3185a8df719604a85443bb9ca279f5a2",
            ],
          },
        },
      ],
    };
    fbApiConvertion.mutate(viewContentData);
  };
  useEffect(() => {
    fbConvertionEvent("ViewContent");
  }, []);

  const goToPrice = () => {
    fbConvertionEvent("AddToCart");
    productsDataRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const openWhatsappToBuy = async (kit) => {
    fbConvertionEvent("InitiateCheckout");
    switch (kit) {
      case 1:
        window.open(`https://wa.link/73nut6`, "_blank");
        break;
      case 2:
        window.open(`https://wa.link/5iwal7`, "_blank");
        break;
      case 3:
        window.open(`https://wa.link/bubqoa`, "_blank");
        break;
    }
  };

  const iconBenefits = [
    {
      icon: (
        <LightingIcon
          fill={theme.colors.white}
          width={isMobile ? "60px" : "100px"}
          height={isMobile ? "60px" : "100px"}
        />
      ),
      text: "Aumenta tu energía del día a día",
    },
    {
      icon: (
        <PriceIcon
          fill={theme.colors.white}
          width={isMobile ? "60px" : "100px"}
          height={isMobile ? "60px" : "100px"}
        />
      ),
      text: "Precio accesible",
    },
    {
      icon: (
        <VerifyIcon
          fill={theme.colors.white}
          width={isMobile ? "60px" : "100px"}
          height={isMobile ? "60px" : "100px"}
        />
      ),
      text: "La mejor calidad",
    },
    {
      icon: (
        <HealtIcon
          fill={theme.colors.white}
          width={isMobile ? "60px" : "100px"}
          height={isMobile ? "60px" : "100px"}
        />
      ),
      text: "Energía de forma saludable",
    },
    {
      icon: (
        <LeafIcon
          fill={theme.colors.white}
          width={isMobile ? "60px" : "100px"}
          height={isMobile ? "60px" : "100px"}
        />
      ),
      text: "Hecho con ingredientes naturales",
    },
  ];

  return (
    <Container>
      <Div
        background={theme.colors.green300}
        p={isMobile ? "20px" : "40px"}
        direction={isMobile ? "column" : "row"}
        align="center"
        justify="center"
        gap={!isMobile && "30px"}
      >
        {isMobile && (
          <Text
            color={theme.colors.white}
            weight={theme.fonts.weight.extrabold}
            size={theme.fonts.size.h3}
            mb="10px"
          >
            ⚡ Optimiza tu Energía con un <br /> Complejo B
          </Text>
        )}

        <Col>
          {!isMobile && (
            <Text
              color={theme.colors.black}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h2}
              mb="20px"
            >
              🚚 Envío Gratis y 💵 pago contraentrega
            </Text>
          )}

          <img
            src={women}
            alt="women"
            width={isMobile ? "250px" : "600px"}
            height={isMobile ? "250px" : "600px"}
            style={{
              borderRadius: "20px",
              objectFit: "contain",
              border: "5px solid #fff",
            }}
          />
        </Col>

        <Col m={isMobile && "10px 0"} align="center">
          {!isMobile && (
            <Text
              color={theme.colors.white}
              weight={theme.fonts.weight.extrabold}
              size={isMobile ? theme.fonts.size.h3 : theme.fonts.size.h1}
              style={{ padding: !isMobile && "0 10px" }}
            >
              ⚡ Optimiza tu Energía con un <br /> Complejo B
            </Text>
          )}
          <Text
            color={theme.colors.white}
            weight={theme.fonts.weight.extrabold}
            size={isMobile ? theme.fonts.size.h3 : theme.fonts.size.h1}
            style={{
              backgroundColor: theme.colors.green500,
              padding: isMobile ? "5px 10px" : "5px 20px",
              borderRadius: "5px",
            }}
          >
            de alta calidad
          </Text>
          {isMobile && (
            <Text
              color={theme.colors.black}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h5}
              mt="20px"
              style={{ textDecoration: "underline" }}
            >
              🚚 Envío Gratis y 💵 pago contraentrega
            </Text>
          )}
          <Text
            color={theme.colors.white}
            weight={theme.fonts.weight.regular}
            size={isMobile ? theme.fonts.size.h5 : theme.fonts.size.h3}
            mt={isMobile ? "10px" : "20px"}
          >
            Aumenta tu energía,{" "}
            <span style={{ fontWeight: theme.fonts.weight.bold }}>
              🚀 Tecnología bicapa de liberación prolongada
            </span>{" "}
            mejora tu calidad de sueño 💤 y contribuye al funcionamiento normal
            del sistema inmune 💪 y nervioso.
          </Text>
          <img
            src={bComplexImg}
            alt="b-complex"
            width={isMobile ? "250px" : "400px"}
            height={isMobile ? "300px" : "500px"}
            style={{ objectFit: "contain" }}
          />
          <Button
            size={isMobile ? theme.fonts.size.h5 : theme.fonts.size.h3}
            weight={theme.fonts.weight.bold}
            onClick={goToPrice}
            width={isMobile ? "100%" : "400px"}
            background={theme.colors.red}
            radius="50px"
            style={{ padding: "0 20px" }}
            animate={true}
          >
            COMPRAR AHORA
          </Button>
          <Div gap="5px" direction={isMobile ? "column" : "row"} align="center">
            <Row
              gap="8px"
              align="center"
              justify="center"
              style={{
                backgroundColor: theme.colors.green500,
                padding: isMobile ? "5px 10px" : "5px 20px",
                borderRadius: "5px",
              }}
            >
              <UserCheckIcon width={"24px"} height={"24px"} />
              <Text
                color={theme.colors.white}
                weight={theme.fonts.weight.bold}
                size={isMobile ? theme.fonts.size.h6 : theme.fonts.size.h4}
              >
                Calidad
              </Text>
              <Div>
                {[...Array(5)].map((_, index) => (
                  <StarFilled
                    key={index}
                    style={{ color: theme.colors.white }}
                  />
                ))}
              </Div>
            </Row>
            <Row
              gap="8px"
              align="center"
              justify="center"
              style={{
                backgroundColor: theme.colors.green500,
                padding: isMobile ? "5px 10px" : "5px 20px",
                borderRadius: "5px",
              }}
            >
              <Text
                color={theme.colors.white}
                weight={theme.fonts.weight.bold}
                size={isMobile ? theme.fonts.size.h6 : theme.fonts.size.h4}
              >
                Garantía de satisfacción
              </Text>
              <CheckPasswordIcon
                stroke={theme.colors.green}
                width={"24px"}
                height={"24px"}
              />
            </Row>
          </Div>
          <Text
            color={theme.colors.white}
            weight={theme.fonts.weight.regular}
            size={isMobile ? theme.fonts.size.h6 : theme.fonts.size.h4}
            mt="5px"
          >
            Único complejo que te otorga{" "}
            <span style={{ fontWeight: theme.fonts.weight.bold }}>
              8 horas de liberación de energía
            </span>
          </Text>
        </Col>
      </Div>

      <Div
        background={theme.colors.black}
        p={isMobile ? "10px 20px" : "10px 220px"}
        direction="column"
        align="center"
        justify="center"
        gap="10px"
      >
        <Text
          color={theme.colors.white}
          weight={theme.fonts.weight.extrabold}
          size={isMobile ? theme.fonts.size.h4 : theme.fonts.size.h2}
          mt={isMobile ? "20px" : "50px"}
        >
          😴 ¿Te sientes que descansas poco, agotado y sin energía durante el
          día? 💥
        </Text>
        <Text
          color={theme.colors.white}
          weight={theme.fonts.weight.medium}
          size={isMobile ? theme.fonts.size.h5 : theme.fonts.size.h3}
          mb="10px"
        >
          El estrés, el cansancio y la falta de vitaminas pueden afectar tu
          nivel de energía y bienestar general 🌿.
        </Text>
        <Text
          color={theme.colors.white}
          weight={theme.fonts.weight.extrabold}
          size={isMobile ? theme.fonts.size.h3 : theme.fonts.size.h1}
          style={{
            backgroundColor: theme.colors.green300,
            padding: isMobile ? "5px 10px" : "5px 20px",
            borderRadius: "5px",
          }}
        >
          LLEGÓ LA SOLUCIÓN:
        </Text>
        <Text
          color={theme.colors.white}
          weight={theme.fonts.weight.medium}
          size={isMobile ? theme.fonts.size.h5 : theme.fonts.size.h3}
        >
          <span
            style={{
              fontWeight: theme.fonts.weight.extrabold,
              color: theme.colors.green300,
            }}
          >
            🌟 B Complex Dual Release 🌟
          </span>{" "}
          aporta las vitaminas B esenciales durante 8 horas ⏰ que tu cuerpo
          necesita para enfrentar los desafíos del día a día 💪.
        </Text>

        <Div
          width={isMobile ? "250px" : "350px"}
          height={isMobile ? "420px" : "600px"}
          radius="50px"
          style={{
            position: "relative",
            overflow: "hidden",
            border: `5px solid ${theme.colors.black}`,
          }}
        >
          <video
            width="100%"
            height="100%"
            style={{ objectFit: "cover" }}
            controls
            defaultPlaybackRate={1.25}
          >
            <source src={bComplexVideo} type="video/mp4" />
            Tu navegador no soporta el elemento de video.
          </video>
        </Div>
        <Button
          size={isMobile ? theme.fonts.size.h5 : theme.fonts.size.h3}
          weight={theme.fonts.weight.bold}
          onClick={goToPrice}
          width={isMobile ? "100%" : "400px"}
          background={theme.colors.red}
          radius="50px"
          style={{ padding: "0 20px" }}
          animate={true}
        >
          COMPRAR AHORA
        </Button>
        <Text
          color={theme.colors.white}
          weight={theme.fonts.weight.extrabold}
          size={isMobile ? theme.fonts.size.h4 : theme.fonts.size.h2}
          mt="15px"
          mb="10px"
        >
          ¿Qué hace irresistible a B Complex Dual Release?
        </Text>
        <Div
          width={"100%"}
          style={{
            flexWrap: "wrap",
          }}
          gap={isMobile ? "10px" : "40px"}
          justify={"center"}
          m="0 0 20px 0"
        >
          {iconBenefits.map((benefit, index) => (
            <Col key={index} align="center" width="100px">
              {benefit.icon}
              <Text
                color={theme.colors.white}
                size={isMobile ? theme.fonts.size.xs : theme.fonts.size.sm}
              >
                {benefit.text}
              </Text>
            </Col>
          ))}
        </Div>
      </Div>

      <Div
        background={theme.colors.green300}
        p={isMobile ? "20px" : "20px 220px"}
        direction="column"
        align="center"
        justify="center"
        gap="10px"
      >
        <Text
          color={theme.colors.white}
          weight={theme.fonts.weight.extrabold}
          size={isMobile ? theme.fonts.size.h4 : theme.fonts.size.h3}
          mt="30px"
          mb="10px"
          style={{
            backgroundColor: theme.colors.green500,
            padding: isMobile ? "5px 10px" : "5px 20px",
            borderRadius: "20px",
          }}
        >
          🌿 Ingredientes Naturales de cultivos orgánicos 🌱
        </Text>
        <Text
          color={theme.colors.black}
          weight={theme.fonts.weight.extrabold}
          size={isMobile ? theme.fonts.size.h4 : theme.fonts.size.h2}
          mb="20px"
        >
          Todo un proceso controlado de forma natural,{" "}
          <span style={{ textDecoration: "underline" }}>
            desde la semilla 🌱 hasta la pastilla 💊
          </span>
        </Text>
        <Div
          direction={isMobile ? "column" : "row"}
          align={"center"}
          justify={isMobile ? "center" : "space-between"}
          gap={isMobile ? "5px" : "10px"}
          width={!isMobile && "100%"}
        >
          <Text
            color={theme.colors.white}
            weight={theme.fonts.weight.extrabold}
            size={isMobile ? theme.fonts.size.h4 : theme.fonts.size.h2}
            style={{
              backgroundColor: theme.colors.green500,
              padding: isMobile ? "5px 10px" : "5px 20px",
              borderRadius: "5px",
            }}
          >
            B
            <span
              style={{
                fontSize: isMobile
                  ? theme.fonts.size.default
                  : theme.fonts.size.h6,
              }}
            >
              2
            </span>{" "}
            y B
            <span
              style={{
                fontSize: isMobile
                  ? theme.fonts.size.default
                  : theme.fonts.size.h6,
              }}
            >
              12
            </span>
          </Text>
          <Text
            color={theme.colors.black}
            weight={theme.fonts.weight.extrabold}
            size={isMobile ? theme.fonts.size.h5 : theme.fonts.size.h3}
          >
            💥 VITAMINAS QUE SE LIBERAN DE INMEDIATO ⏱️
          </Text>
        </Div>
        <Div
          direction={isMobile ? "column" : "row"}
          align={"center"}
          justify={isMobile ? "center" : "space-between"}
          gap={isMobile ? "5px" : "10px"}
          width={!isMobile && "100%"}
        >
          <Text
            color={theme.colors.white}
            weight={theme.fonts.weight.extrabold}
            size={isMobile ? theme.fonts.size.h4 : theme.fonts.size.h2}
            style={{
              backgroundColor: theme.colors.green500,
              padding: isMobile ? "5px 10px" : "5px 20px",
              borderRadius: "5px",
            }}
          >
            B
            <span
              style={{
                fontSize: isMobile
                  ? theme.fonts.size.default
                  : theme.fonts.size.h6,
              }}
            >
              1
            </span>
            , B
            <span
              style={{
                fontSize: isMobile
                  ? theme.fonts.size.default
                  : theme.fonts.size.h6,
              }}
            >
              3
            </span>
            , B
            <span
              style={{
                fontSize: isMobile
                  ? theme.fonts.size.default
                  : theme.fonts.size.h6,
              }}
            >
              5
            </span>
            , B
            <span
              style={{
                fontSize: isMobile
                  ? theme.fonts.size.default
                  : theme.fonts.size.h6,
              }}
            >
              6
            </span>
            , B
            <span
              style={{
                fontSize: isMobile
                  ? theme.fonts.size.default
                  : theme.fonts.size.h6,
              }}
            >
              7
            </span>{" "}
            y B
            <span
              style={{
                fontSize: isMobile
                  ? theme.fonts.size.default
                  : theme.fonts.size.h6,
              }}
            >
              9
            </span>
          </Text>
          <Text
            color={theme.colors.black}
            weight={theme.fonts.weight.extrabold}
            size={isMobile ? theme.fonts.size.h5 : theme.fonts.size.h3}
          >
            🌱 LIBERA LENTA Y CONSTANTEMENTE EL RESTO DE LAS VITAMINAS DEL
            COMPLEJO B DURANTE 8 HORAS 🕒
          </Text>
        </Div>
        <Div
          direction={isMobile ? "column" : "row"}
          m="20px 0 10px 0"
          align="center"
          gap="10px"
          justify="start"
        >
          <LeafIcon fill={theme.colors.white} width={"80px"} height={"80px"} />
          <Text
            color={theme.colors.white}
            weight={theme.fonts.weight.semibold}
            size={isMobile ? theme.fonts.size.h5 : theme.fonts.size.h3}
          >
            <span style={{ fontWeight: theme.fonts.weight.extrabold }}>
              Contiene espirulina natural
            </span>{" "}
            cultivada en granjas orgánicas de forma sustentable
          </Text>
        </Div>

        <Button
          size={isMobile ? theme.fonts.size.h5 : theme.fonts.size.h3}
          weight={theme.fonts.weight.bold}
          onClick={goToPrice}
          width={isMobile ? "100%" : "400px"}
          background={theme.colors.red}
          radius="50px"
          style={{ padding: "0 20px" }}
          animate={true}
        >
          Quiero el mío
        </Button>
      </Div>

      <Div
        background={theme.colors.black}
        p={isMobile ? "20px" : "20px 220px"}
        direction="column"
        align="center"
        justify="center"
      >
        <Text
          color={theme.colors.white}
          weight={theme.fonts.weight.extrabold}
          size={isMobile ? theme.fonts.size.h4 : theme.fonts.size.h2}
          mt="20px"
        >
          🌟¿Por qué elegir Nutrilite? 🌟
        </Text>
        <Text
          color={theme.colors.white}
          weight={theme.fonts.weight.semibold}
          size={isMobile ? theme.fonts.size.h5 : theme.fonts.size.h3}
          mb="40px"
        >
          Sabemos que hay muchas opciones en el mercado, pero Nutrilite destaca
          por su calidad, pureza y beneficios comprobados.
        </Text>

        {isMobile && (
          <Text
            color={theme.colors.white}
            weight={theme.fonts.weight.semibold}
            size={isMobile ? theme.fonts.size.h5 : theme.fonts.size.h3}
          >
            Diferente suplementos:
          </Text>
        )}
        <Card
          p="5px"
          background={theme.colors.green300}
          align="center"
          m="0 0 40px 0"
        >
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              textAlign: "left",
            }}
          >
            <thead>
              <tr
                style={{
                  backgroundColor: theme.colors.green500,
                  color: "white",
                  fontSize: isMobile
                    ? theme.fonts.size.xxs
                    : theme.fonts.size.h4,
                }}
              >
                <th
                  style={{
                    padding: "10px",
                    borderTopLeftRadius: "20px",
                  }}
                ></th>
                <th style={{ padding: "10px" }}>
                  {isMobile ? "Nutrilite" : "Nutrilite (Orgánico) ✅"}
                </th>
                <th style={{ padding: "10px" }}>
                  {isMobile ? "Natural" : "Suplemento Natural ⚠️"}
                </th>
                <th style={{ padding: "10px", borderTopRightRadius: "20px" }}>
                  {isMobile ? "Químico" : "Suplemento Químico ❌"}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    padding: "10px",
                    fontWeight: theme.fonts.weight.bold,
                    fontSize: isMobile
                      ? theme.fonts.size.xxs
                      : theme.fonts.size.h4,
                  }}
                >
                  Origen
                </td>
                <td
                  style={{
                    padding: "10px",
                    fontWeight: theme.fonts.weight.bold,
                    fontSize: isMobile
                      ? theme.fonts.size.xxs
                      : theme.fonts.size.h4,
                  }}
                >
                  {isMobile ? "🌱" : "Cultivos puros sin químicos"}
                </td>
                <td
                  style={{
                    padding: "10px",
                    fontWeight: theme.fonts.weight.bold,
                    fontSize: isMobile
                      ? theme.fonts.size.xxs
                      : theme.fonts.size.h4,
                  }}
                >
                  {isMobile ? "⚠️" : "Puede tener conservantes"}
                </td>
                <td
                  style={{
                    padding: "10px",
                    fontWeight: theme.fonts.weight.bold,
                    fontSize: isMobile
                      ? theme.fonts.size.xxs
                      : theme.fonts.size.h4,
                  }}
                >
                  {isMobile ? "❌" : "100% sintético"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    padding: "10px",
                    fontWeight: theme.fonts.weight.bold,
                    fontSize: isMobile
                      ? theme.fonts.size.xxs
                      : theme.fonts.size.h4,
                  }}
                >
                  Efectividad
                </td>
                <td
                  style={{
                    padding: "10px",
                    fontWeight: theme.fonts.weight.bold,
                    fontSize: isMobile
                      ? theme.fonts.size.xxs
                      : theme.fonts.size.h4,
                  }}
                >
                  {isMobile ? "👍" : "Máxima biodisponibilidad"}
                </td>
                <td
                  style={{
                    padding: "10px",
                    fontWeight: theme.fonts.weight.bold,
                    fontSize: isMobile
                      ? theme.fonts.size.xxs
                      : theme.fonts.size.h4,
                  }}
                >
                  Media
                </td>
                <td
                  style={{
                    padding: "10px",
                    fontWeight: theme.fonts.weight.bold,
                    fontSize: isMobile
                      ? theme.fonts.size.xxs
                      : theme.fonts.size.h4,
                  }}
                >
                  {isMobile ? "👎" : "Variable, a veces baja"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    padding: "10px",
                    fontWeight: theme.fonts.weight.bold,

                    fontSize: isMobile
                      ? theme.fonts.size.xxs
                      : theme.fonts.size.h4,
                  }}
                >
                  Pureza
                </td>
                <td
                  style={{
                    padding: "10px",
                    fontWeight: theme.fonts.weight.bold,
                    fontSize: isMobile
                      ? theme.fonts.size.xxs
                      : theme.fonts.size.h4,
                  }}
                >
                  {isMobile ? "✔️" : "Sin aditivos ni tóxicos"}
                </td>
                <td
                  style={{
                    padding: "10px",
                    fontWeight: theme.fonts.weight.bold,
                    fontSize: isMobile
                      ? theme.fonts.size.xxs
                      : theme.fonts.size.h4,
                  }}
                >
                  {isMobile ? "🔄" : "Puede incluir algunos procesados"}
                </td>
                <td
                  style={{
                    padding: "10px",
                    fontWeight: theme.fonts.weight.bold,
                    fontSize: isMobile
                      ? theme.fonts.size.xxs
                      : theme.fonts.size.h4,
                  }}
                >
                  {isMobile ? "🛑" : "Contiene colorantes y conservantes"}
                </td>
              </tr>
            </tbody>
          </table>

          <Text
            color={theme.colors.white}
            weight={theme.fonts.weight.bold}
            size={isMobile ? theme.fonts.size.h5 : theme.fonts.size.h3}
            mt="20px"
            mb="20px"
          >
            ¡Elige lo mejor para tu salud! 💪
          </Text>
        </Card>

        <Button
          size={isMobile ? theme.fonts.size.h5 : theme.fonts.size.h3}
          weight={theme.fonts.weight.bold}
          onClick={goToPrice}
          width={isMobile ? "100%" : "400px"}
          background={theme.colors.red}
          radius="50px"
          style={{ padding: "0 20px" }}
          animate={true}
        >
          Pedir aquí
        </Button>
      </Div>

      <Div
        background={theme.colors.green300}
        p={"20px"}
        direction="column"
        align="center"
        justify="center"
        gap="10px"
      >
        <Text
          color={theme.colors.black}
          weight={theme.fonts.weight.extrabold}
          size={isMobile ? theme.fonts.size.h4 : theme.fonts.size.h1}
          mt={isMobile ? "20px" : "40px"}
        >
          Testimonios
        </Text>
        <Row
          justify="center"
          align="center"
          width="100%"
          gap="10px"
          style={{ flexWrap: "wrap" }}
        >
          {testimonials.map((testimony) => {
            return (
              <img
                src={testimony?.img}
                alt="testimony"
                width={isMobile ? "250px" : "400px"}
                height={isMobile ? "250px" : "400px"}
                style={{
                  borderRadius: "20px",
                  objectFit: "contain",
                  border: `5px solid ${theme.colors.black}`,
                }}
              />
            );
          })}
        </Row>
      </Div>

      <Div
        background={theme.colors.black}
        p={isMobile ? "20px" : "50px"}
        direction="column"
        align="center"
        justify="center"
        gap="10px"
        ref={productsDataRef}
      >
        <Div
          gap={isMobile ? "20px" : "50px"}
          justify="space-around"
          align="center"
          direction={isMobile ? "column" : "row"}
          width="100%"
        >
          <Col
            justify={isMobile ? "center" : "space-between"}
            align="center"
            height={"auto"}
            style={{
              gap: "15px",
              borderColor: theme.colors.green,
              borderWidth: "2px",
              borderRadius: "20px",
              borderStyle: "solid",
              padding: "10px",
            }}
          >
            <Text
              color={theme.colors.white}
              weight={theme.fonts.weight.extrabold}
              size={isMobile ? theme.fonts.size.h3 : theme.fonts.size.h2}
              mt="10px"
            >
              2 Meses de Complejo B <br></br> $98.000 <br></br>(60 tabletas)
            </Text>
            <Text
              color={theme.colors.green300}
              weight={
                isMobile
                  ? theme.fonts.weight.extrabold
                  : theme.fonts.weight.bold
              }
              size={isMobile ? theme.fonts.size.h5 : theme.fonts.size.h4}
              mt="10px"
            >
              por solo $1.650 x día
            </Text>
            <ImgWrapper
              background={bComplex1}
              width={isMobile ? "200px" : "350px"}
              height={isMobile ? "200px" : "350px"}
              alt={"one-bcomplex"}
              radius="30px"
            />
            <Text
              color={theme.colors.white}
              weight={theme.fonts.weight.extrabold}
              size={isMobile ? theme.fonts.size.default : theme.fonts.size.h4}
              mt="10px"
            >
              🚚 Envío Gratis y 💵 pago contraentrega
            </Text>
            <Button
              size={theme.fonts.size.h3}
              weight={theme.fonts.weight.bold}
              onClick={() => openWhatsappToBuy(1)}
              width="100%"
              height="80px"
              m="10px"
              background={theme.colors.red}
              radius="50px"
              style={{ padding: "0 20px 0 20px" }}
            >
              Pedir
            </Button>
          </Col>
          <Col
            justify="center"
            align="center"
            height="auto"
            style={{
              gap: "15px",
              borderColor: theme.colors.yellow,
              borderWidth: "2px",
              borderRadius: "20px",
              borderStyle: "solid",
              padding: "10px",
            }}
          >
            <Text
              color={theme.colors.white}
              weight={theme.fonts.weight.extrabold}
              size={isMobile ? theme.fonts.size.h3 : theme.fonts.size.h1}
              style={{
                backgroundColor: theme.colors.yellow,
                padding: isMobile ? "5px 10px" : "5px 20px",
                borderRadius: "5px",
              }}
            >
              MAYOR DESCUENTO
            </Text>
            <Text
              color={theme.colors.white}
              weight={theme.fonts.weight.extrabold}
              size={isMobile ? theme.fonts.size.h3 : theme.fonts.size.h2}
            >
              4 Meses de Complejo B <br></br>
              <span style={{ textDecoration: "line-through" }}> $196.000</span>
              <br></br>
              (120 tabletas)
              <br></br>
              $177.700
            </Text>
            <Text
              color={theme.colors.green300}
              weight={
                isMobile
                  ? theme.fonts.weight.extrabold
                  : theme.fonts.weight.bold
              }
              size={isMobile ? theme.fonts.size.h5 : theme.fonts.size.h4}
            >
              con ¡descuento!
            </Text>

            <ImgWrapper
              background={bComplex2}
              width={isMobile ? "200px" : "350px"}
              height={isMobile ? "200px" : "350px"}
              alt={"two-bCpmplex"}
              radius="30px"
            />
            <Text
              color={theme.colors.white}
              weight={theme.fonts.weight.extrabold}
              size={isMobile ? theme.fonts.size.default : theme.fonts.size.h4}
              mt="10px"
            >
              🚚 Envío Gratis y 💵 pago contraentrega
            </Text>
            <Button
              size={theme.fonts.size.h3}
              weight={theme.fonts.weight.bold}
              onClick={() => openWhatsappToBuy(2)}
              width="100%"
              height="80px"
              m="10px"
              background={theme.colors.red}
              radius="50px"
              style={{ padding: "0 20px 0 20px" }}
            >
              Pedir
            </Button>
          </Col>
          <Col
            justify="center"
            align="center"
            height="auto"
            style={{
              gap: "15px",
              borderColor: theme.colors.green,
              borderWidth: "2px",
              borderRadius: "20px",
              borderStyle: "solid",
              padding: "10px",
            }}
          >
            <Text
              color={theme.colors.white}
              weight={theme.fonts.weight.extrabold}
              size={isMobile ? theme.fonts.size.h3 : theme.fonts.size.h1}
              style={{
                backgroundColor: theme.colors.green,
                padding: isMobile ? "5px 10px" : "5px 20px",
                borderRadius: "5px",
              }}
            >
              MAYOR EFECTIVIDAD
            </Text>
            <Text
              color={theme.colors.white}
              weight={theme.fonts.weight.extrabold}
              size={isMobile ? theme.fonts.size.h3 : theme.fonts.size.h2}
            >
              Multivitamínico + Complejo B <br></br>
              <span style={{ textDecoration: "line-through" }}> $150.000</span>
              <br></br>
              (30 + 60 tabletas)
              <br></br>
              $134.000
            </Text>
            <Text
              color={theme.colors.green300}
              weight={
                isMobile
                  ? theme.fonts.weight.extrabold
                  : theme.fonts.weight.bold
              }
              size={isMobile ? theme.fonts.size.h5 : theme.fonts.size.h4}
            >
              con ¡descuento!
            </Text>

            <ImgWrapper
              background={bComplex3}
              width={isMobile ? "200px" : "350px"}
              height={isMobile ? "200px" : "350px"}
              alt={"prendeTuDia"}
              radius="30px"
            />
            <Text
              color={theme.colors.white}
              weight={theme.fonts.weight.extrabold}
              size={isMobile ? theme.fonts.size.default : theme.fonts.size.h4}
              mt="10px"
            >
              🚚 Envío Gratis y 💵 pago contraentrega
            </Text>
            <Button
              size={theme.fonts.size.h3}
              weight={theme.fonts.weight.bold}
              onClick={() => openWhatsappToBuy(3)}
              width="100%"
              height="80px"
              m="10px"
              background={theme.colors.red}
              radius="50px"
              style={{ padding: "0 20px 0 20px" }}
            >
              Pedir
            </Button>
          </Col>
        </Div>
      </Div>
    </Container>
  );
};
