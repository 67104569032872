import testimonial1 from "../../assets/images/bComplexTestimonial1.jpeg";
import testimonial2 from "../../assets/images/bComplexTestimonial2.jpeg";
import testimonial3 from "../../assets/images/bComplexTestimonial3.jpeg";
import testimonial4 from "../../assets/images/bComplexTestimonial4.jpeg";
export const testimonials = [
  {
    name: "Diego G.",
    testimony:
      "Nunca pensé que un suplemento pudiera darme tanta energía. El Complejo B me mantiene activa todo el día y, además, me ha ayudado a reducir el estrés. ¡Es mi nuevo imprescindible!",
    img: testimonial1,
  },
  {
    name: "José L.",
    testimony:
      "Llevo un tiempo tomando el Complejo B y la diferencia es increíble. Mi energía está mucho más equilibrada y siento que mi mente está más clara. ¡Lo recomiendo totalmente!",
    img: testimonial2,
  },
  {
    name: "Ana P.",
    testimony:
      "Con mis días tan ajetreados, siempre busco algo que me dé energía sin ser tan pesado. El Complejo B de Nutrilite es justo lo que necesitaba. Me siento más enfocado y con más vitalidad.",
    img: testimonial3,
  },
  {
    name: "Carlos E.",
    testimony:
      "Como vegano, siempre me preocupo por encontrar suplementos naturales que me ayuden. El Complejo B de Nutrilite me ha dado justo lo que necesitaba para sentirme bien, sin complicaciones.",
    img: testimonial4,
  },
];
