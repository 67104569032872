export const theme = {
  colors: {
    white: "#FFFFFF",
    white100: "#FFFFFF29",
    white200: "#00000000",
    black: "#000000",

    yellow: "#edd30c",

    green: "#399D67",
    green50: "#EFF6F1",
    green100: "#D4F4DE",
    green200: "#BDF6CF",
    green300: "#63B844",
    green500: "#0D492F",

    greenBlue: "#89c0a0",

    springGreen: "#259D71",

    gray50: "#F0F0F0",
    gray100: "#D9D9D9",
    gray500: "#8D8D8D",
    gray800: "#484848",
    gray900: "#3C3C3C",

    red: "#FF0000",

    orange: "#FF3600",
    orange500: "#F07D00",

    blue: "#00B1FF",
    blue100: "#ADD8E9",
    blue400: "#2789A7",
    blue600: "#065049",

    scroll: {
      gray: "#D9D9D9",
      green: "#00565F",
    },
    alerts: {
      success: "#CADDD4",
      error: "#FFE5E5",
      iconSuccess: "#4A7668",
      iconError: "#F87C7C",
    },
  },

  fonts: {
    family: "Montserrat, sans-serif",
    size: {
      high: "4rem",
      h1: "2.625rem",
      h2: "2rem",
      h3: "1.6875rem",
      h4: "1.5rem",
      h5: "1.125rem",
      h6: "1.0625rem",
      default: "1rem",
      sm: "0.875rem",
      xs: "0.75rem",
      xxs: "0.65rem",
    },
    weight: {
      extralight: 200,
      light: 300,
      regular: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
      extrabold: 800,
    },
  },
  breakpoints: {
    xs: "320px",
    sm: "480px",
    md: "820px",
    lg: "992px",
    xl: "1200px",
  },
  zIndex: {
    base: 1,
    menu: 2,
    overlay: 3,
    modal: 4,
    toast: 5,
  },
};
